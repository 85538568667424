} catch (e) {
    StackTrace
        .fromError(e)
        .then(function(stack){
            e.stack = stack;
            e.ua = window.navigator.userAgent;
            e.href = window.location.href;
            e.type = 'Collaterate global try...catch error.';
            return e;
        })
        .then(function(e){
            window.tbgErrorLogger.log(e)
        })
        .catch(function(response){
            console.error('Could not reach Collaterate error logging service: ' + response);
        });
}
